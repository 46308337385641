import { ref } from '@vue/composition-api'
import { cloneDeep, isEmpty } from 'lodash-es'

import { getDifference } from '@/@core/utils/utils'
import { apiSupportRequest } from '@/api'

import useToast from '@useToast'

export default function useCreateHandle() {
  const { toastSuccess, toastError } = useToast()
  const blankReq = {
    id: null,
    pnr: null,
    phone: null,
    content: null,
    note: null,
  }
  // Loading
  const loading = ref(false)

  const createReq = ref(cloneDeep(blankReq))
  const item = ref(cloneDeep(blankReq))

  async function createHandle(refFormObserver) {
    try {
      let response
      const success = await refFormObserver.validate()
      if (success) {
        const payload = {
          ...createReq.value,
          pnr: createReq.value.pnr.trim().toUpperCase(),
        }
        response = await apiSupportRequest.create(payload)
        toastSuccess('supportReq.createSuccess')
      }
      return response
    } catch (error) {
      toastError(error)
      throw error
    }
  }

  async function updateHandle(refFormObserver) {
    try {
      const success = await refFormObserver.validate()
      if (success) {
        const req = getDifference(createReq.value, item.value)
        if (isEmpty(req)) {
          return
        }
        await apiSupportRequest.update(createReq.value.id, req)
        toastSuccess('supportReq.updateSuccess')
      }
    } catch (error) {
      toastError(error)
      throw error
    }
  }
  async function onLoadHandle(id) {
    try {
      if (id) {
        const { data } = await apiSupportRequest.getById(id)
        Object.assign(createReq.value, data)
        Object.assign(item.value, data)
      } else {
        createReq.value = cloneDeep(blankReq)
      }
      loading.value = false
    } catch (error) {
      toastError(error)
      throw error
    }
  }
  async function getBookingByCode(code) {
    try {
      let rs
      if (code) {
        const { data } = await apiSupportRequest.getBookingByCode(code)
        rs = data
      }
      loading.value = false
      return rs
    } catch (error) {
      toastError(error)
      throw error
    }
  }

  return {
    // Loading
    loading,
    createReq,

    createHandle,
    updateHandle,
    onLoadHandle,
    getBookingByCode,
  }
}
