<template lang="">
  <div>
    <div v-if="!optionsPeriod">
      <b-card
        v-if="!webOnly && options"
        style="width: fit-content"
        class="border mb-0"
        body-class="py-1 px-2"
      >
        <h4 class="font-weight-bolder text-dark">
          Chi tiết gói
        </h4>

        <div style="display: flex; gap: 40px">
          <div
            v-for="(listItem, listIndex) in chunk(options, Math.max(5, Math.round(options.length / 2)))"
            :key="listIndex"
          >
            <div
              v-for="({description, value}, optIndex) in listItem"
              :key="`${listIndex}_${optIndex}`"
              style="width: 100%"
            >
              <div
                class="d-flex-between font-weight-bolder"
                style="gap: 40px"
              >
                <div>{{ description }}:</div>
                <div v-if="typeof value === 'number'">
                  <span class="">{{ formatCurrency(value) }}</span>
                </div>
                <div v-else-if="typeof value === 'boolean'">
                  <feather-icon
                    :class="value ? 'text-success' : 'text-danger'"
                    :icon="value? 'CheckIcon' : 'XIcon'"
                    size="16"
                  />
                </div>
                <div v-else-if="typeof value === 'string'">
                  <span class="">{{ value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <BAlert
        v-else-if="webOnly"
        show
        variant="info"
        style="width: fit-content"
        class="p-1 px-2"
      >
        Hỗ trợ setup web bán lẻ.
      </BAlert>
      <BAlert
        v-else-if="!options"
        show
        variant="warning"
        style="width: fit-content"
        class="p-1 px-2"
      >
        Lỗi, không có mô tả
      </BAlert>
    </div>
    <div v-else>
      <b-card
        style="width: fit-content"
        class="border mb-0"
        body-class="py-1 px-2"
      >
        <h4 class="font-weight-bolder text-dark">
          Chi tiết sử dụng gói
        </h4>
        <div style="width: 400px">
          <div
            v-for="(value, key) of optionsPeriodToView"
            :key="key"
            class="d-flex-between font-weight-bolder"
          >
            <div>{{ $t(`packageConfig.columns.${key}`) }}:</div>
            <div v-if="typeof value.value === 'number'">
              <span class="">{{ formatCurrency(value.value) }}</span>
              <span
                v-if="value.limit"
                class=""
              > / {{ formatCurrency(value.limit) }}</span>
            </div>
            <div v-else>
              {{ value.value }}
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { BCard, BAlert } from 'bootstrap-vue'
import chunk from 'lodash/chunk'
import { computed, toRefs } from '@vue/composition-api'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

export default {
  components: {
    BCard, BAlert,
  },
  props: {
    options: { type: [Array, null], default: null },
    webOnly: { type: Boolean, default: false },
    optionsPeriod: { type: [Object, null], default: null },
    limitList: { type: [Object, null], default: null },
  },
  setup(props) {
    const { limitList, optionsPeriod } = toRefs(props)
    const optionsPeriodToView = computed(() => ({
      searchCount: {
        value: optionsPeriod.value.searchCount,
        limit: limitList.value.searchLimit,
      },
      bookCount: {
        value: optionsPeriod.value.bookCount,
        limit: limitList.value.bookingLimit,
      },
      issueCount: {
        value: optionsPeriod.value.issueCount,
        limit: limitList.value.issueLimit,
      },
      periodStart: { value: convertISODateTime(optionsPeriod.value.periodStart).date },
      periodEnd: { value: convertISODateTime(optionsPeriod.value.periodEnd).date },
    }))
    return { formatCurrency, chunk, optionsPeriodToView }
  },
}
</script>
<style lang="">

</style>
