import { ref, computed, watch } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import { apiPackageConfig, apiAgencies } from '@/api'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import useToast from '@useToast'

export default function useAgencyPackageConfig() {
  const { route } = useRouter()
  const isHistory = computed(
    () => route.value.name === 'apps-agencyPackageHistory-list',
  )
  const loading = ref(false)
  const loadingAgencies = ref(false)
  const loadingPackageConfigs = ref(false)
  const { toastSuccess, toastError } = useToast()
  const agencyCodeFilter = ref()
  const packageConfigIdFilter = ref()
  const isExpiredFilter = ref()
  const sortBy = ref(null)
  const isSortDirDesc = ref(true)
  const resultArray = ref([])
  const currentPage = ref(1)
  const sizePerPage = ref(20)
  const totalItems = ref(0)

  const fromItem = computed(
    () => sizePerPage.value * (currentPage.value - 1) + 1,
  )
  const toItem = computed(() => sizePerPage.value * currentPage.value >= totalItems.value
    ? totalItems.value
    : sizePerPage.value * currentPage.value)

  const tableColumns = ref([
    'agency',
    'parentAgency',
    'packageConfig',
    'packageConfigIssueLimit',
    'monthsUse',
    'issueLimit',
    'expiredAt',
    'enable',
    'action',
  ])
  const tableColumnsHistory = ref([
    { label: 'agency', key: 'agency', sortable: false },
    { label: 'parentAgency', key: 'parentAgency', sortable: false },
    { label: 'packageConfig', key: 'packageConfig', sortable: false },
    { label: 'searchCount', key: 'searchCount', sortable: true },
    { label: 'bookCount', key: 'bookCount', sortable: true },
    { label: 'issueCount', key: 'issueCount', sortable: true },
    { label: 'periodStart', key: 'periodStart', sortable: true },
    { label: 'periodEnd', key: 'periodEnd', sortable: true },
    { label: 'action', key: 'action' },
  ])
  function clearFilter() {
    agencyCodeFilter.value = undefined
    packageConfigIdFilter.value = undefined
    isExpiredFilter.value = undefined
  }

  const isExpiredOptions = ref([
    { label: 'expired', value: true },
    { label: 'unexpired', value: false },
    { label: 'all', value: null },
  ])
  const isOverOptions = ref([
    { label: 'over', value: true },
    { label: 'notover', value: false },
    { label: 'all', value: null },
  ])
  const refTable = ref()

  function fetchAgencyPackageConfigs(history = false) {
    loading.value = true
    const params = {
      agencyCode: agencyCodeFilter.value || undefined,
      packageConfigId: packageConfigIdFilter.value || undefined,
      isExpired: isExpiredFilter.value ?? undefined,
      page: currentPage.value || 1,
      size: sizePerPage.value || 20,
      sortBy: sortBy.value ? sortBy.value : undefined,
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }
    apiPackageConfig
      .findAllAgencyPackageConfigs(params, history)
      .then(res => {
        resultArray.value = res.data.items
        totalItems.value = res.data.count
        if (!history) {
          const allowPaymentQR = res.data?.allowPaymentQR || false
          store.dispatch('userStore/setAllowPaymentQR', allowPaymentQR)
        }
      })
      .catch(error => {
        console.error({ error })
        toastError('Lỗi tải dữ liệu')
      })
      .finally(() => {
        loading.value = false
      })
  }
  const loadingPeriod = ref(false)
  async function findByPeriod(id) {
    loadingPeriod.value = true
    const res = await apiPackageConfig.findByPeriod(id)
    loadingPeriod.value = false
    return res.data
  }

  async function updateAgencyPackageConfig(id, item) {
    loading.value = true
    const payload = {
      ...item,
    }
    try {
      await apiPackageConfig.updateAgencyPackageConfigs(id, payload)
      toastSuccess('Cập nhật thành công')
    } catch (error) {
      toastError('Lỗi tải dữ liệu')
    } finally {
      loading.value = false
    }
  }

  async function buyPackage(payload) {
    loading.value = true
    try {
      const res = await apiPackageConfig.buyPackage(payload)
      toastSuccess('Tạo thành công')
      return res
    } catch (error) {
      console.error({ error })
      toastError('Lỗi tạo')
      return error
    } finally {
      loading.value = false
    }
  }

  watch(
    [
      currentPage,
      sizePerPage,
      agencyCodeFilter,
      packageConfigIdFilter,
      isExpiredFilter,
      sortBy,
      isSortDirDesc,
    ],
    () => {
      fetchAgencyPackageConfigs(isHistory.value)
    },
  )

  async function changeActive(item) {
    await updateAgencyPackageConfig(item.id, { enable: !item.enable })
    fetchAgencyPackageConfigs()
  }

  async function changeDomain(id, domains) {
    await updateAgencyPackageConfig(id, { domains })
  }

  const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
    loadingAgencies.value = true
    apiAgencies
      .fetchAgencies({ searchText })
      .then(response => {
        resolve(response.data.items)
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loadingAgencies.value = false
      })
  })

  // ANCHOR fetch Agency
  const agencyOptionsDefault = ref([])
  const agencyOptions = ref([])

  function handleOpenAgency() {
    if (!agencyOptionsDefault.value.length) {
      fetchAgencies().then(res => {
        agencyOptions.value = res
        agencyOptionsDefault.value = res
      })
    } else {
      agencyOptions.value = agencyOptionsDefault.value
    }
  }

  const handleSearchAgency = debounce(search => {
    if (search) {
      fetchAgencies(search).then(res => {
        agencyOptions.value = res
      })
    }
  }, 300)

  // ANCHOR fetch package config
  const packageConfigOptionsDefault = ref([])
  const packageConfigOptions = ref([])

  const fetchPackageConfigs = (searchText = undefined) => new Promise((resolve, reject) => {
    loadingPackageConfigs.value = true
    apiPackageConfig
      .findAllPackageConfigs({ searchText, page: 1, size: 20 })
      .then(response => {
        resolve(response.data.items)
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loadingPackageConfigs.value = false
      })
  })

  function handleOpenPackageConfig() {
    if (!packageConfigOptionsDefault.value.length) {
      fetchPackageConfigs().then(res => {
        packageConfigOptions.value = res
        packageConfigOptionsDefault.value = res
      })
    } else {
      packageConfigOptions.value = packageConfigOptionsDefault.value
    }
  }

  const handleSearchPackageConfig = debounce(search => {
    if (search) {
      fetchPackageConfigs(search).then(res => {
        packageConfigOptions.value = res
      })
    }
  }, 300)

  return {
    agencyCodeFilter,
    packageConfigIdFilter,
    isExpiredFilter,
    resultArray,
    currentPage,
    sizePerPage,
    toItem,
    fromItem,
    loading,
    totalItems,
    tableColumns,
    clearFilter,
    fetchAgencyPackageConfigs,
    buyPackage,
    changeActive,
    changeDomain,
    updateAgencyPackageConfig,
    fetchAgencies,
    handleOpenAgency,
    handleSearchAgency,
    agencyOptions,
    loadingAgencies,
    isExpiredOptions,
    isOverOptions,
    tableColumnsHistory,
    packageConfigOptions,
    handleOpenPackageConfig,
    handleSearchPackageConfig,
    loadingPackageConfigs,
    refTable,

    sortBy,
    isSortDirDesc,

    findByPeriod,
    loadingPeriod,
  }
}
