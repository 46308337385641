<template>
  <b-modal
    id="create-support-modal"
    size="lg"
    centered
    no-close-on-backdrop
    no-close-on-esc
    header-bg-variant="light-info"
    hide-footer
    :title="id?$t('supportReq.form.edit'): $t('supportReq.form.title')"
    @show="()=>{
      if(!booking && !id){
        bookingInfo=null
        onLoadHandle()
      }else if(id){
        onLoadHandle(id)
      }
    }"
    @close="()=>{
      if(!booking && !id){
        bookingInfo=null
        onLoadHandle()
      }
    }"
  >
    <IAmOverlay :loading="loading">
      <validation-observer ref="refFormObserver">
        <b-form>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="validationContext"
                :name="$t('supportReq.form.pnr')"
                vid="pnr"
                rules="required"
              >
                <b-form-group label-for="pnr">
                  <template #label>
                    {{ $t('supportReq.form.pnr') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="pnr"
                    v-model="createReq.pnr"
                    :name="$t('supportReq.form.pnr')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('supportReq.form.ph.pnr')"
                    :disabled="isRoleF1 || booking != null"
                    maxlength="6"
                    debounce="300"
                    @input="val=>handleDebounceBookingCode(val)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="bookingInfo">
              <div
                v-for="(trip, index) of (bookingInfo.itineraries)"
                :key="index"
                class="text-nowrap text-body-3"
              >
                <div
                  v-for="(segment,indexSegment) in trip"
                  :key="indexSegment"
                  class="d-flex flex-nowrap my-50"
                >
                  <IAmLogoAirline
                    v-if="segment.airline"
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    size="xs"
                    rounded
                    custom-class="mr-50"
                  />
                  {{ convertShortTrip(segment) }}
                </div>
              </div>
            </b-col>

            <b-col sm="12">
              <validation-provider
                #default="validationContext"
                :name="$t('supportReq.form.content')"
                vid="content"
                :rules="isRoleF2?'required':''"
              >
                <b-form-group label-for="content">
                  <template #label>
                    {{ $t('supportReq.form.content') }}
                    <span
                      v-if="isRoleF2"
                      class="text-danger"
                    >*</span>
                  </template>
                  <b-form-textarea
                    id="content"
                    v-model="createReq.content"
                    :placeholder="$t('supportReq.form.ph.content')"
                    :name="$t('supportReq.form.content')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :disabled="isRoleF1"
                    rows="5"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR input phone -->
            <b-col md="12">
              <b-form-group label-for="phone">
                <template #label>
                  {{ $t('supportReq.form.phone') }}
                </template>
                <b-form-input
                  id="phone"
                  v-model="createReq.phone"
                  v-remove-non-numeric-chars.allNumber
                  name="phone"
                  :placeholder="$t('supportReq.form.phone')"
                  :disabled="false"
                  :required="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="createReq.id != null"
              sm="12"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('supportReq.form.note')"
                vid="note"
                :rules="isRoleF1?'required':''"
              >
                <b-form-group label-for="note">
                  <template #label>
                    {{ $t('supportReq.form.note') }}
                    <span
                      v-if="isRoleF1"
                      class="text-danger"
                    >*</span>
                  </template>
                  <b-form-textarea
                    id="note"
                    v-model="createReq.note"
                    :placeholder="$t('supportReq.form.ph.note')"
                    :name="$t('supportReq.form.note')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :disabled="isRoleF2"
                    rows="5"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR status -->
            <b-col
              v-if="createReq.id != null && isRoleF1"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('supportReq.form.status')"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <template #label>
                    {{ $t('supportReq.form.status') }}
                    <span class="text-danger">*</span>
                  </template>
                  <v-select
                    id="status"
                    v-model="createReq.status"
                    :options="status"
                    :clearable="false"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('supportReq.form.ph.status')"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(`supportReq.status.${data.label}`) }}
                      </span>
                    </template>
                    <template #selected-option="data">
                      <span>
                        {{ $t(`supportReq.status.${data.label}`) }}
                      </span>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="isRoleF2"
              cols="12"
              class="text-danger"
            >
              <span>{{ $t('supportReq.note.title') }}:</span>
              <ul class="dot">
                <li>{{ $t('supportReq.note.note1') }}</li>
                <li>{{ $t('supportReq.note.note2') }}</li>
                <li>{{ $t('supportReq.note.note3') }}</li>
                <li>{{ $t('supportReq.note.note4') }}</li>
              </ul>
            </b-col>
          </b-row>
        </b-form>
        <div class="d-flex-center gap-3">
          <b-button
            variant="info"
            @click="save"
          >
            {{ $t('save') }}
          </b-button>
          <b-button
            variant="secondary"
            @click="() => {
              if(!booking && !id){
                bookingInfo=null
                onLoadHandle()
              }
              $bvModal.hide('create-support-modal')
            }"
          >
            {{ $t('cancel') }}
          </b-button>
        </div>
      </validation-observer>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import {
  BButton,
  BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea,
  BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { convertISODateTime, convertShortTrip, resolveAirlineFlightNumber } from '@/@core/utils/filter'
import IAmLogoAirline from '@/components/IAmLogoAirline.vue'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'

import useSupportReqHandle from '../useSupportReqHandle'
import useCreateHandle from './useCreateHandle'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    IAmLogoAirline,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    vSelect: () => import('vue-select'),
  },

  props: {
    // for update
    id: {
      type: Number,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation()
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const {
      loading,
      createReq,
      createHandle,
      updateHandle,
      onLoadHandle,
      getBookingByCode,
    } = useCreateHandle()
    const bookingInfo = ref(null)
    const {
      status, airlineSupports,
    } = useSupportReqHandle()

    async function save() {
      if (createReq.value.id) {
        await updateHandle(this.$refs.refFormObserver)
      } else {
        const data = await createHandle(this.$refs.refFormObserver)
        emit('set-id', data.id)
        onLoadHandle()
      }
      emit('refresh')
      this.$bvModal.hide('create-support-modal')
    }

    async function handleDebounceBookingCode(cc) {
      try {
        cc = cc.toUpperCase().replace(/\s+/g, '')
        if (cc.length === 6) {
          const data = await getBookingByCode(cc)
          bookingInfo.value = data
        } else {
          bookingInfo.value = null
        }
      } catch (error) {
        createReq.value.pnr = ''
      }
    }

    watch([props], () => {
      const { id, booking } = props
      if (id) {
        createReq.value.id = id
        onLoadHandle(createReq.value.id)
      }
      if (booking) {
        createReq.value.pnr = booking.bookingCode
        bookingInfo.value = booking
        loading.value = false
      }
    }, { deep: true })

    return {
      isRoleF1,
      isRoleF2,
      status,
      airlineSupports,
      loading,
      createReq,
      refFormObserver,
      bookingInfo,

      save,
      onLoadHandle,
      getValidationState,
      handleDebounceBookingCode,
      resolveAirlineFlightNumber,
      convertISODateTime,
      convertShortTrip,
    }
  },
}
</script>
